import StoreBase from '../storeBase';
import { action, makeObservable, observable } from 'mobx';
import { landingPageSectionType } from '../../core/constants/enums';
import landingPageApiHandler from 'core/services/apiService/apiHandlers/landingPageApiHandler';

const transformLandingPageData = (data) => {
	let { sections, menuItems } = data;
	sections = sections.map(item => ({
		...item,
		name: landingPageSectionType[item.type] || ''
	}));

	return { sections, menuItems };
};

const includeSections = [
	landingPageSectionType.ABOUT,
	landingPageSectionType.CONTACT_US,
	landingPageSectionType.REQUEST_COURSE,
	landingPageSectionType.BECOME_INSTRUCTOR
];

class LandingPageStore extends StoreBase {
	sectionsContent = this.toState({});
	instructor = this.toState({});
	searchedCourses = this.toState({ filteredCount: 0, items: [] });
	coursesFilters = this.toState({});
	menuItems = this.toState({ filteredCount: 0, items: [] });
	courses = this.toState([]);
	// sectionsData = this.toState({});
	landingPageData = this.toState({ sections: [], menuItems: [] });
	instructors = this.toState({ items: [], filteredCount: 0 });
	coursePublicDetail = this.toState({});
	courseSchedules = this.toState([]);

	constructor() {
		super();
		makeObservable(this, {
			courses: observable,
			menuItems: observable,
			landingPageData: observable,
			coursesFilters: observable,
			sectionsContent: observable,
			instructor: observable,
			searchedCourses: observable,
			coursePublicDetail: observable,
			courseSchedules: observable,
			instructors: observable,
			setCourses: action,
			setMenuItems: action,
			setLandingPageData: action,
			setSectionsContent: action,
			setInstructors: action,
			setCoursesFilters: action,
			setSearchedCourses: action,
			setCoursePublicDetail: action,
			setInstructor: action
		});
	}

	setMenuItems(menuItems) {
		this.menuItems = menuItems;
	};

	setSearchedCourses(searchedCourses) {
		this.searchedCourses = searchedCourses;
	}

	setLandingPageData(landingPageData) {
		this.landingPageData = landingPageData;
	}

	setSectionsContent(sectionsContent) {
		this.sectionsContent = sectionsContent;
	}

	setCourses(courses) {
		this.courses = courses;
	};

	setInstructor(instructor) {
		this.instructor = instructor;
	}

	setCourseSchedules(courseSchedules) {
		this.courseSchedules = courseSchedules;
	}

	setCoursePublicDetail(coursePublicDetail) {
		this.coursePublicDetail = coursePublicDetail;
	}

	setCoursesFilters(coursesFilters) {
		this.coursesFilters = coursesFilters;
	}

	setInstructors(instructors) {
		this.instructors = instructors;
	}

	async subscribers(data) {
		const res = await landingPageApiHandler.subscribers(data);
		return this.isOk(res);
	}

	async unsubscribe(data) {
		const res = await landingPageApiHandler.unsubscribe(data);
		return this.isOk(res);
	}

	async sendMessage(data) {
		const res = await landingPageApiHandler.sendMessage(data);
		return this.isOk(res);
	}

	async scheduleRequests(data) {
		const res = await landingPageApiHandler.scheduleRequests(data);
		return this.isOk(res);
	}

	async getLandingPageData(filter) {
		this.setLandingPageData(this.toState(this.landingPageData.data, true));
		const res = await landingPageApiHandler.getLandingPageData(filter);
		const result = res?.result ? this.toState(transformLandingPageData(res.result)) : {
			...this.landingPageData,
			isLoading: false
		};
		this.setLandingPageData(result);
	}

	async getSections({ id, type }) {
		this.setSectionsContent(this.toState(this.sectionsContent.data, true));
		const res = await landingPageApiHandler.getSections(id);
		const result = res.result ? this.toState({
			...this.sectionsContent.data,
			[type]: includeSections.includes(type) ? res.result[0] ? res.result[0] : null : res.result.length ? res.result : null
		}) : { ...this.sectionsContent, isLoading: false };
		this.setSectionsContent(result);
	}

	async getCourses(languageId) {
		return await landingPageApiHandler.getCourses(languageId);
	}

	async getInstructors(filter) {
		this.setInstructors(this.toState(this.instructors.data, true));
		const res = await landingPageApiHandler.getInstructors(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.instructors, isLoading: false };
		this.setInstructors(result);
	}

	async getInstructor(instructorId) {
		this.setInstructor(this.toState(this.instructor.data, true));
		const res = await landingPageApiHandler.getInstructor(instructorId);
		const result = res?.result ? this.toState(res.result) : { ...this.instructor, isLoading: false };
		this.setInstructor(result);

	}

	async getCoursesFilters() {
		this.setCoursesFilters(this.toState(this.coursesFilters.data, true));
		const res = await landingPageApiHandler.getCoursesFilters();
		const result = res?.result ? this.toState(res.result) : { ...this.coursesFilters, isLoading: false };
		this.setCoursesFilters(result);
	}

	async getCourseSchedules(id) {
		this.setCourseSchedules(this.toState(this.courseSchedules.data, true));
		const res = await landingPageApiHandler.getCourseSchedules(id);
		const result = res?.result ? this.toState(res.result) : { ...this.courseSchedules, isLoading: false };
		this.setCourseSchedules(result);
	}

	async searchCourses(filter) {
		this.setSearchedCourses(this.toState(this.searchedCourses.data, true));
		const res = await landingPageApiHandler.searchCourses(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.searchedCourses, isLoading: false };
		this.setSearchedCourses(result);
	}

	async getPublicCourseDetail(courseId) {
		this.setCoursePublicDetail(this.toState(this.coursePublicDetail.data, true));
		const res = await landingPageApiHandler.getPublicCourseDetail(courseId);
		const result = res?.result ? this.toState(res.result) : { ...this.coursePublicDetail, isLoading: false };
		this.setCoursePublicDetail(result);
	}
}

const landingPageStore = new LandingPageStore();
export default landingPageStore;