const socialData = [
	{ id: 1, name: 'email', href: 'mailto:support@deva.one', icon: 'envelope' },
	{ id: 2, name: 'telegram', href: 'https://t.me/deverno', icon: 'telegram' },
	{ id: 3, name: 'whatsapp', href: 'https://wa.me/16507411200', icon: 'whatsapp' },
	{ id: 4, name: 'facebook', href: 'https://fb.com/DevaOneAcademy', icon: 'facebook-square' },
	{ id: 5, name: 'youtube', href: 'https://www.youtube.com/channel/UCJdC-5RLv0GlWwtOTNUcrGw', icon: 'youtube-play' },
	{ id: 6, name: 'linkedin', href: 'https://www.linkedin.com/company/deva-one', icon: 'linkedin' },
	{ id: 7, name: 'twitter', href: 'https://twitter.com/devaacademy', icon: 'twitter' }
];

export {
	socialData
};