import Fetch from "../fetch";
import ApiHandler from '../apiHandler';
import { CONTENT_TYPES } from 'core/constants/apiConstants/contentTypes';

const END_POINTS = {
	me: 'me',
	prefix: 'Users',
	users: 'users',
	profile: 'profile',
	avatar: 'avatar',
	resume: 'resume',
	activate: 'activate',
	studentProfile: 'studentProfile',
	instructorProfile: 'instructorProfile'
};

class UsersApiHandler extends ApiHandler {
	async setActivateKey(activationKey) {
		return await Fetch.post(`${END_POINTS.prefix}/${END_POINTS.activate}/${activationKey}`);
	}

	async getProfile() {
		return await Fetch.get(`${END_POINTS.prefix}/${END_POINTS.profile}`);
	}

	async getMe() {
		return await Fetch.get(`${END_POINTS.prefix}/${END_POINTS.me}`);
	}

	async addInstructorProfile(formData) {
		return await Fetch.post(`${END_POINTS.prefix}/${END_POINTS.instructorProfile}`, formData);
	}

	async getUserProfile() {
		return await Fetch.get(`${END_POINTS.prefix}/${END_POINTS.profile}`);
	};

	async editInstructorResume(formData) {
		return Fetch.patch(`${END_POINTS.prefix}/${END_POINTS.instructorProfile}/${END_POINTS.resume}`, formData, { contentType: CONTENT_TYPES.FORM_DATA });
	}

	async editInstructorProfile(data) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.instructorProfile}`, data);
	}

	async editStudentProfile(data) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.studentProfile}`, data);
	}

	async editUser(formData) {
		return Fetch.put(`${END_POINTS.prefix}/${END_POINTS.users}`, formData, { contentType: CONTENT_TYPES.FORM_DATA });
	}

	async updateUserAvatar(formData) {
		return Fetch.put(`${END_POINTS.prefix}/${END_POINTS.avatar}`, formData, { contentType: CONTENT_TYPES.FORM_DATA })
	}

	async getInstructorProfileResume(data = {}) {
		return await Fetch.get(`${END_POINTS.prefix}/${END_POINTS.instructorProfile}/${END_POINTS.resume}`, {}, { contentType: CONTENT_TYPES.OCTET_STREAM });
	}
}

const usersApiHandler = new UsersApiHandler();
export default usersApiHandler;