import React from 'react';
import { Divider } from 'antd';
import { ScheduleProvider } from './context';
import useTranslation from 'hooks/useTranslation';
import T from 'core/translations/translations.json';
import { Typography } from 'view/components/shared';
import { dateTimeZone } from 'core/helpers/momentTimeZone';
import { DATE_FORMATS } from 'core/constants/globalConstants';
import { scheduleStatusByClassName } from 'core/constants/enums';
import './index.scss';


const { BaseBodyText } = Typography;
/**
 * It takes in a child component and returns a new component that has access to the `translate` function
 * @returns The children of the Table component.
 */
const Table = ({ children }) => {
	const translate = useTranslation();
	return (
		<ScheduleProvider.Provider value={{ translate }}>
			{children}
		</ScheduleProvider.Provider>
	);
};

Table.Row = ({ children, ...restProps }) => {
	return (
		<div className="schedule_item_container" {...restProps}>
			{children}
		</div>
	);
};

Table.Header = ({ children }) => {
	return (
		<div className="item_header">
			{children}
		</div>
	);
};

Table.Th = ({ className, children }) => {
	return (
		<div className={className}>
			{children}
		</div>
	);
};


Table.Body = ({ children }) => {
	return (
		<div className="item_body">
			{children}
		</div>
	);
};

Table.Column = ({
					title,
					children,
					className = ''
				}) => {
	return (
		<ScheduleProvider.Consumer>
			{
				({ translate }) => (
					<div className={`column_item ${className}`}>
						<div>
							<BaseBodyText
								size="base"
								fontWeight="medium"
								className="textEllipsisBlock column_title"
							>
								{translate(title)}
							</BaseBodyText>

							<div className="column_item_content">
								{children}
							</div>
						</div>
					</div>
				)
			}
		</ScheduleProvider.Consumer>
	);
};

Table.Status = ({ type }) => {
	return (
		<ScheduleProvider.Consumer>
			{({ translate }) => {
				return (
					<div className="status_container">
						<span className={`is_active ${scheduleStatusByClassName[type]}`}/>
						<span>{translate(scheduleStatusByClassName[type])}</span>
					</div>
				);
			}}
		</ScheduleProvider.Consumer>
	);
};

Table.DateRange = ({ startDate, endDate, examDate }) => {
	return (
		<ScheduleProvider.Consumer>
			{
				({ translate }) => (
					<div className="date_range_container">
						<span>
							{dateTimeZone(startDate, DATE_FORMATS.POINT_SHORT)}
						</span>
						<span>-</span>
						<span>
							{dateTimeZone(endDate, DATE_FORMATS.POINT_SHORT)}
						</span>
						{
							examDate && (
								<span>
									<Divider type="vertical"/>
									{translate(T.EXAM_DATE)} ({dateTimeZone(examDate, DATE_FORMATS.POINT_LONG_TIME)})
								</span>
							)
						}
					</div>
				)
			}
		</ScheduleProvider.Consumer>

	);
};

Table.Span = ({ children }) => {
	return (
		<span className="column_text_content">{children}</span>
	);
};

Table.Day = ({ day }) => {
	return (
		<div className="column_day_content">
			<span>{day}</span>
		</div>
	);
};

Table.Time = ({ time }) => {
	return (
		<div className="column_time_content">
			<span>{time}</span>
		</div>
	);
};

Table.Button = ({ text, disabled, onClick }) => {
	return (
		<button onClick={onClick} className={`${disabled ? 'disabled_button' : ''} schedule_table_button`}>
			{text}
		</button>
	);
};


Table.Type = ({ text, type }) => {
	return <span className={`schedule_type_content ${type}`}>{text}</span>;
};

Table.Price = ({ prices }) => {
	const isArray = Array.isArray(prices);

	const priceNode = isArray ? prices.map((item) => {
		return (
			<div className="price_item" key={item.id}>
				<span>{item.price}</span>
				<span className="price_code">({item.currencyCode})</span>
			</div>
		);
	}) : <span>-</span>;

	return (
		<div className="price_container">
			{priceNode}
		</div>
	);
};

Table.InlineContent = ({ children }) => {
	return (
		<div className="inline_content">
			{children}
		</div>
	);
};

export default Table;