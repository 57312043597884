import { lazy } from 'react';
import defineGuards from './defineGuards/defineGuards';
import IsNotAuthLayer from './layers/IsNotAuthLayer';
import IsRegisterLayer from './layers/IsRegisterLayer';
import IsForgetPassword from './layers/isForgetPassword';
import isAuthLayer from './layers/isAuthLayer';
import isProfileLayer from './layers/isProfileLayer';
import IsDeclinedProfileLayer from './layers/isDeclinedProfileLayer';
import IsSuccessSetupProfileLayer from './layers/isSuccessSetupProfileLayer';
import { instructorRoutes } from './instructor';
import { studentRoutes } from './student';
import { publicRoutes } from './public';

const Chat = lazy(() => import('view/pages/chat'));
const NotFound = lazy(() => import('view/pages/notFound'));
const Courses = lazy(() => import('view/pages/courses'));
const Login = lazy(() => import('view/pages/auth/login'));
const Dashboard = lazy(() => import('view/pages/dashboard'));
const Register = lazy(() => import('view/pages/auth/register'));
const DeclinedProfile = lazy(() => import('view/pages/declinedProfile'));
const UserProfileSetUp = lazy(() => import('view/pages/userProfileSetUp'));
const ResetPassword = lazy(() => import('view/pages/auth/resetPassword'));
const ForgetPassword = lazy(() => import('view/pages/auth/forgetPassword'));
const SuccessSetupProfile = lazy(() => import('view/pages/successSetupProfile'));
const InstructorAddProfile = lazy(() => import('view/pages/instructorAddProfile'));
const CongratsConfirmation = lazy(() => import('view/pages/congratsConfirmation'));
const CongratsRegistration = lazy(() => import('view/pages/congratsRegistration'));
const SuccessForgetPassword = lazy(() => import('view/pages/successForgetPassword'));
const UserRegistrationType = lazy(() => import('view/pages/auth/userRegistrationType'));
const Unsubscribe = lazy(() => import('view/pages/unsubscribe'));

export const ROUTE_CONSTANTS = {
	EMPTY: '/',
	CHAT: '/chat',
	ABOUT: '/about',
	LOGIN: '/login',
	PROFILE: '/profile',
	LANDING: '/landing',
	COURSES: '/courses',
	CABINET: '/cabinet',
	PAYMENTS: '/payments',
	SCHEDULE: '/schedule',
	REVENUES: '/revenues',
	REGISTER: '/register',
	DASHBOARD: '/dashboard',
	CONTACT_US: '/contact-us',
	INSTRUCTOR: '/instructor',
	ADD_COURSES: '/add-courses',
	UNSUBSCRIBE: '/unsubscribe',
	CONFIRMATION: '/confirmation',
	EDIT_COURSES: '/edit-courses',
	PROFILE_SETUP: '/profile-setup',
	COURSE_DETAIL: '/course-detail',
	REGISTER2: '/register/:userType',
	RESET_PASSWORD: '/reset-password',
	BROWSE_COURSES: '/browse-courses',
	REQUEST_COURSE: '/request-course',
	OUR_INSTRUCTORS: '/our-instructors',
	FORGET_PASSWORD: '/forget-password',
	DECLINED_PROFILE: '/declined-profile',
	REGISTER_SUCCESS: '/register-success',
	COURSE_DETAIL_SEO: '/course-detail',
	BECOME_INSTRUCTOR: '/become-instructorChat',
	SUCCESS_SETUP_PROFILE: '/success-setup-profile',
	COURSE_DETAIL_SEO_FACEBOOK: '/course-detail',
	SUCCESS_FORGET_PASSWORD: '/success-forget-password'
};

const rootRoutes = [
	{
		path: `${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.DASHBOARD}`,
		component: () => defineGuards([ isAuthLayer ], Dashboard),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.RESET_PASSWORD,
		component: ResetPassword,
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.PROFILE,
		component: () => defineGuards([ isProfileLayer ], InstructorAddProfile),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.SUCCESS_FORGET_PASSWORD,
		component: () => defineGuards([ IsForgetPassword ], SuccessForgetPassword),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.LOGIN,
		component: () => defineGuards([ IsNotAuthLayer ], Login),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.REGISTER,
		component: () => defineGuards([ IsNotAuthLayer ], UserRegistrationType),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.REGISTER2,
		component: () => defineGuards([ IsNotAuthLayer ], Register),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.REGISTER_SUCCESS,
		component: () => defineGuards([ IsRegisterLayer ], CongratsRegistration),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.CONFIRMATION,
		component: CongratsConfirmation,
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.UNSUBSCRIBE,
		component: Unsubscribe,
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.SUCCESS_SETUP_PROFILE,
		component: () => defineGuards([ IsSuccessSetupProfileLayer ], SuccessSetupProfile),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.FORGET_PASSWORD,
		component: () => defineGuards([ IsNotAuthLayer ], ForgetPassword),
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.PROFILE_SETUP}`,
		component: () => defineGuards([ isAuthLayer ], UserProfileSetUp),
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.CHAT}`,
		component: () => defineGuards([ isAuthLayer ], Chat),
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.COURSES}`,
		component: () => defineGuards([ isAuthLayer ], Courses),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.DECLINED_PROFILE,
		component: () => defineGuards([ IsDeclinedProfileLayer ], DeclinedProfile),
		exact: true
	},
	...instructorRoutes,
	...studentRoutes,
	...publicRoutes,
	{
		path: '*',
		component: NotFound,
		exact: true
	}
];

export default rootRoutes;